import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { allParticipantsPath, claimedRewardsPath } from 'config/paths'
import { LoadComponent } from 'routes/load-component'

const participantChallengeList = lazy(() => import('./participants'))
const collectedRewardList = lazy(() => import('./claimed-rewards'))

export const challengeRoutes = (): RouteObject[] => [
  {
    index: true,
    path: allParticipantsPath(),
    element: <LoadComponent component={participantChallengeList} />,
  },
  {
    index: true,
    path: claimedRewardsPath(),
    element: <LoadComponent component={collectedRewardList} />,
  },
]
