import { createPath } from './path-utils'
import { PathGenerator, PathGeneratorWithParams } from './path.types'

const CHALLENGE_PATH = '/challenges'

export const challengePath: PathGeneratorWithParams = (params) => {
  return createPath(CHALLENGE_PATH, params)
}

export const allParticipantsPath: PathGenerator = () => {
  return createPath(`${CHALLENGE_PATH}/participants`)
}

export const claimedRewardsPath: PathGenerator = () => {
  return createPath(`${CHALLENGE_PATH}/claimed-rewards`)
}
