import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { PUBLIC_WEBSITE_LINK } from 'config/env'
import EventCard from 'pages/app/event-card/event-card'
import GarminConnected from 'pages/app/garmin-connected/garmin-connected'
import JoinGroup from 'pages/app/join-group/join-group'
import LeaderboardCard from 'pages/app/leaderboard-card/leaderboard-card'
import MagicLink from 'pages/app/magic-link'
import ShareEvent from 'pages/app/share-event/share-event'
import ShareLeaderboard from 'pages/app/share-leaderboard/share-leaderboard'
import ShareStory from 'pages/app/share-story/share-story'
import StravaConnected from 'pages/app/strava-connected/strava-connected'
import WahooConnected from 'pages/app/wahoo-connected/wahoo-connected'

import { RedirectExternalRoute } from './redirect-route'
import { ErrorAppRoutes } from './shared/error-routes'

import '../assets/scss/app.scss'

const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<RedirectExternalRoute redirectTo={PUBLIC_WEBSITE_LINK} />} />
    <Route index path='groups/:groupId/stories/:storyId' element={<ShareStory />} />
    <Route index path='join-group/:id' element={<JoinGroup />} />
    <Route index path='group/:groupId/event/:eventId' element={<ShareEvent />} />
    <Route index path='strava-connected' element={<StravaConnected />} />
    <Route index path='garmin-connected' element={<GarminConnected />} />
    <Route index path='public/events/:id' element={<EventCard />} />
    <Route index path='public/leaderboard/:id' element={<LeaderboardCard />} />
    <Route index path='group-leaderboard/:id' element={<ShareLeaderboard />} />
    <Route index path='onboarding/strava-connected' element={<StravaConnected />} />
    <Route index path='onboarding/garmin-connected' element={<GarminConnected />} />
    <Route index path='wahoo-connected' element={<WahooConnected />} />
    <Route index path='onboarding/wahoo-connected' element={<WahooConnected />} />
    <Route index path='ml/:id' element={<MagicLink />} />
    <Route path='/'>
      {ErrorAppRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Route>
  </Routes>
)

export default AppRoutes
