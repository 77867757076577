import { useEffect, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { DEEP_LINK_BASE_URL } from 'config/env'

const MagicLink = (): null => {
  const [searchParams] = useSearchParams()

  const { id } = useParams()

  const verifyType = useMemo(() => searchParams.get('type') || 'login', [searchParams])

  const searchParamsString = useMemo(() => {
    const searchParams = new URLSearchParams()
    searchParams.append('type', verifyType)

    return searchParams.toString()
  }, [verifyType])

  const deepLinkUrl = `${DEEP_LINK_BASE_URL}ml/${id.split('?')[0]}?${searchParamsString}`

  const openFactumApp = () => {
    setTimeout(() => {
      location.replace(deepLinkUrl)
    }, 0)
  }

  useEffect(() => {
    if (!window) return

    openFactumApp()
  }, [])

  return null
}

export default MagicLink
