import React, { lazy } from 'react'
import { useRoutes } from 'react-router-dom'

import { Layout } from 'components'
import AuthLayout from 'components/layout/auth-layout'
import { profilePath } from 'config/paths'
import { adminRoutes } from 'pages/dashboard/admins/route'
import { analyticRoutes } from 'pages/dashboard/analytics/route'
import { betaTesterRoutes } from 'pages/dashboard/beta-testers/route'
import { challengeRoutes } from 'pages/dashboard/challenges/route'
import { eventRoutes } from 'pages/dashboard/events/route'
import { featureFlagRoutes } from 'pages/dashboard/feature-flags/route'
import { groupRoutes } from 'pages/dashboard/groups/route'
import { storyRoutes } from 'pages/dashboard/stories/route'
import { userRoutes } from 'pages/dashboard/users/route'

import { LoadComponent } from './load-component'
import PrivateRoute from './private-route'
import RedirectRoute from './redirect-route'
import AuthRoutes from './shared/auth-routes'
import ErrorRoutes from './shared/error-routes'

const profile = lazy(() => import('pages/auth/profile/profile'))

const AdminRoutes = () =>
  useRoutes([
    {
      path: '/',
      element: <RedirectRoute route='/' redirectTo='/analytics/users' />,
    },
    {
      path: '/dashboard',
      element: <RedirectRoute route='/dashboard' redirectTo='/analytics/users' />,
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: AuthRoutes,
    },
    {
      path: '/',
      element: <PrivateRoute component={Layout} />,
      children: [
        {
          path: profilePath(),
          element: <LoadComponent component={profile} />,
        },
        {
          path: 'accounts',
          element: <RedirectRoute route='accounts' redirectTo='/account/users' />,
          children: [...userRoutes(), ...betaTesterRoutes(), ...adminRoutes()],
        },
        ...groupRoutes(),
        ...eventRoutes(),
        ...featureFlagRoutes(),
        ...storyRoutes(),
        ...challengeRoutes(),
        {
          path: 'analytics',
          element: <RedirectRoute route='analytics' redirectTo='/analytics/users' />,
          children: analyticRoutes(),
        },
      ],
    },
    {
      path: '/',
      children: ErrorRoutes,
    },
  ])

export default AdminRoutes
