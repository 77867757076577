{
  "Challenge Progress": "Challenge Progress",
  "Progress": "Progress",
  "Has Claim Reward": "Has Claim Reward",
  "Completed": "Completed",
  "Email Address": "Email Address",
  "Address Line 1": "Address Line 1",
  "Address Line 2": "Address Line 2",
  "Suburb": "Suburb",
  "State": "State",
  "Postcode": "Postcode",
  "Country": "Country",
  "Phone Number": "Phone Number"
}
