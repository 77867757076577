{
  "Account": "Account",
  "Actions": "Actions",
  "Active": "Active",
  "Add New": "Add New",
  "Admin": "Admin",
  "An unexpected error occurred, please try again later.": "An unexpected error occurred, please try again later.",
  "Analytic": "Analytic",
  "Apply": "Apply",
  "Apply Filter": "Apply Filter",
  "Back to": "Back to",
  "Banned": "Banned",
  "Block": "Block",
  "Change Password": "Change Password",
  "Connected App": "Connected App",
  "Country": "Country",
  "Create Date": "Create Date",
  "danger": "Error",
  "Dashboard": "Dashboard",
  "Delete": "Delete",
  "Details": "Details",
  "Display": "Display",
  "Drop files here or click to upload.": "Drop files here or click to upload.",
  "Edit": "Edit",
  "Email": "Email",
  "Enter your email": "Enter your email",
  "Enter your password": "Enter your password",
  "Enter your phone number": "Enter your phone number",
  "Export Data": "Export Data",
  "Factum Dashboard": "Factum Dashboard",
  "Filter": "Filter",
  "First Name": "First Name",
  "Go Back": "Go Back",
  "Go to page": "Go to page",
  "Inactive": "Inactive",
  "Join Date": "Join Date",
  "Last Name": "Last Name",
  "Last Update": "Last Update",
  "Login": "Login",
  "Logout": "Logout",
  "Must be JPG or PNG and less than": "Must be JPG or PNG and less than {{ formattedSize }}",
  "My Account": "My Account",
  "Name": "Name",
  "Navigation": "Navigation",
  "Page current of total": "Page <strong>{{ currentPage }}</strong> of <strong>{{ totalPage }}</strong>",
  "Password": "Password",
  "Phone Number": "Phone Number",
  "Profile Picture": "Profile Picture",
  "Reset": "Reset",
  "Reset All": "Reset All",
  "rows": "{{ pageSize }} Rows",
  "Search Name, Phone Number, etc": "Search Name, Phone Number, etc",
  "Search Name, Phone Number": "Search Name, Phone Number",
  "Select a connected app": "Select a connected app",
  "Select a country": "Select a country",
  "Select a date": "Select a date",
  "Select a status": "Select a status",
  "Status": "Status",
  "Submitting": "Submitting...",
  "success": "Success",
  "total_item_result": "{{ totalItems }} Result",
  "Unexpected Error!": "Unexpected Error!",
  "Update status to": "Update status to {{ status }}",
  "User": "User",
  "Items Selected": "{{ count }} Items Selected",
  "warning": "Warning",
  "Welcome": "Welcome",
  "Your email": "Your email",
  "Your Email": "Your Email",
  "Your first name": "Your first name",
  "Your last name": "Your last name",
  "Your password": "Your password",
  "Your Password": "Your Password",
  "Your phone number": "Your phone number",
  "layout_title_detail": "{{ title }} Detail",
  "layout_title_create": "Create {{ title }}",
  "layout_title_edit": "Edit {{ title }}",
  "layout_title_members": "{{ title }} Members",
  "layout_title_add_members": "Add {{ title }} Members",
  "modal_confirm_status_title": "Are you sure want to update the selected {{ type }}?",
  "modal_confirm_status_body": "You’ll update the selected {{ type }} to {{ status }} by click the update button.",
  "modal_confirm_delete_title": "Are you sure want to delete the selected {{ type }}?",
  "modal_confirm_delete_body": "You’ll delete the {{ type }} permanently by clicking the delete button",
  "Delete Account": "Delete Account",
  "Beta Tester": "Beta Tester",
  "Waiting List": "Waiting List",
  "Delete type": "Delete {{ type }}",
  "Save Changes": "Save Changes",
  "Verified": "Verified",
  "Unverified": "Unverified",
  "Select a type": "Select a type",
  "Toggle Modal": "Toggle Modal",
  "Toggle Sidebar": "Toggle Sidebar",
  "Select": "Select",
  "Confirm Password": "Confirm Password",
  "Confirm your password": "Confirm your password",
  "Requirements": "Requirements :",
  "Send Reset Password": "Send Reset Password",
  "Internal Server Error": "Internal Server Error",
  "Why not try refreshing your page? or you can contact": "Why not try refreshing your page? or you can contact ",
  "Support": "Support",
  "Return Home": "Return Home",
  "Events": "Events",
  "Event": "Event",
  "in": "in",
  "In": "In",
  "Out": "Out",
  "Maybe": "Maybe",
  "Add": "Add",
  "Added": "Added",
  "Confirm": "Confirm",
  "Continue": "Continue",
  "member_one": "{{ count }} member",
  "member_other": "{{ count }} members",
  "Description": "Description",
  "Group": "Group",
  "Members": "Members",
  "Group Name": "Group Name",
  "Copied!": "Copied!",
  "Creator": "Creator",
  "Member Name": "Member Name",
  "Date & Time": "Date & Time",
  "Your group name": "Your group name",
  "Select a member count range": "Select a member count range",
  "100+": "100+",
  "20+": "20+",
  "number_range": "{{ min }}-{{ max }}",
  "modal_confirm_remove_member_title": "Are you sure want to remove this member from group?",
  "modal_confirm_remove_member_body": "You’ll remove the member from group permanently by clicking the remove button",
  "Remove member type": "Remove member from group",
  "Disabled": "Disabled",
  "Current Password": "Current Password",
  "Your Current Password": "Your Current Password",
  "Your New Password": "Your New Password",
  "New Password": "New Password",
  "Confirm New Password": "Confirm New Password",
  "layout_title_profile": "Setting Page",
  "Failed to update group to": "Failed to update group to {{ status }}",
  "Group is successfully update to": "Group is successfully update to {{ status }}",
  "Created Date": "Created Date",
  "Message": "Message",
  "Check on map": "Check on map",
  "Group with this member already exists!": "Group with this member already exists!",
  "Error": "Error",
  "Group ID": "Group ID",
  "hour_one": "{{ count }} hr",
  "hour_other": "{{ count }} hrs",
  "6+ hours": "6+ hours",
  "Road Ride": "Road Ride",
  "MTB Ride": "MTB Ride",
  "Gravel Ride": "Gravel Ride",
  "Run": "Run",
  "Other": "Other",
  "km": "km",
  "Sorry, no data were found.": "Sorry, no data were found.",
  "Upcoming": "Upcoming",
  "Canceled": "Canceled",
  "Cancelled": "Cancelled",
  "Date": "Date",
  "Unexpected Error": "Unexpected Error",
  "An error occurred while fetching data.": "An error occurred while fetching data.",
  "Please try again later.": "Please try again later.",
  "Retry": "Retry",
  "Feature Flag": "Feature Flag",
  "SMS OTP": "SMS OTP",
  "Monthly": "Monthly",
  "Weekly": "Weekly",
  "Daily": "Daily",
  "Public Group": "Public Group",
  "Default Group": "Default Group",
  "Support Group": "Support Group",
  "Partnership Group": "Partnership Group",
  "Standard Group": "Standard Group",
  "Story": "Story",
  "Story Management": "Story Management",
  "Event Management": "Event Management",
  "Group Management": "Group Management",
  "None": "None",
  "Connected Apps": "Connected Apps",
  "Groups": "Groups",
  "Delete Items": "Want to delete '{{ name }}'?",
  "Cancel": "Cancel",
  "Setting": "Setting",
  "Explore the Factum Dashboard": "Explore the Factum Dashboard",
  "Group Analytic Management": "Group Analytic",
  "event_title": "{{ eventTitle }} is on {{ eventDate }}",
  "event_description_no_elevation": "{{ distance }}, {{ duration }} estimated",
  "event_description_no_duration": "{{ distance }}",
  "event_description_no_distance": "{{ duration }} estimated",
  "event_description_default": "{{ distance }}, {{ elevation }} elevation",
  "Challenges": "Challenges",
  "All Participants": "All Participants",
  "Claimed Rewards": "Claimed Rewards",
  "Yes": "Yes",
  "No": "No",
  "View": "View",
  "Progress Details": "Progress Details"
}
