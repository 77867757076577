import {
  adminPath,
  allParticipantsPath,
  betaTesterPath,
  challengePath,
  claimedRewardsPath,
  eventPath,
  featureFlagPath,
  groupAnalyticPath,
  groupPath,
  storyPath,
  userAnalyticPath,
  userPath,
} from 'config/paths'
import { translate as t } from 'locales/i18n'

import { MenuItemType } from './menu.types'

export const MENU_ITEMS: MenuItemType[] = [
  {
    key: 'main-menu',
    label: t('Navigation'),
    isTitle: true,
  },
  {
    key: 'analytics',
    label: t('Analytic'),
    url: userAnalyticPath(),
    icon: 'icon-analytic',
    children: [
      {
        parentKey: 'analytics',
        key: 'users',
        label: t('User'),
        url: userAnalyticPath(),
      },
      {
        parentKey: 'analytics',
        key: 'groups',
        label: t('Group'),
        url: groupAnalyticPath(),
      },
    ],
  },
  {
    key: 'accounts',
    label: t('Account'),
    url: userPath(),
    icon: 'icon-people',
    children: [
      {
        parentKey: 'accounts',
        key: 'admins',
        label: t('Admin'),
        url: adminPath(),
      },
      {
        parentKey: 'accounts',
        key: 'users',
        label: t('User'),
        url: userPath(),
      },
      {
        parentKey: 'accounts',
        key: 'beta-testers',
        label: t('Beta Tester'),
        url: betaTesterPath(),
      },
    ],
  },
  {
    key: 'groups',
    label: t('Group'),
    url: groupPath(),
    icon: 'icon-message',
  },
  {
    key: 'stories',
    label: t('Story'),
    url: storyPath(),
    icon: 'icon-clock',
  },
  {
    key: 'events',
    label: t('Event'),
    url: eventPath(),
    icon: 'icon-calendar',
  },
  {
    key: 'challenges',
    label: t('Challenges'),
    url: challengePath(),
    icon: 'icon icon-TDU icon-20px',
    children: [
      {
        parentKey: 'challenges',
        key: 'participants',
        label: t('All Participants'),
        url: allParticipantsPath(),
      },
      {
        parentKey: 'challenges',
        key: 'claimed-rewards',
        label: t('Claimed Rewards'),
        url: claimedRewardsPath(),
      },
    ],
  },
  {
    key: 'feature-flags',
    label: t('Feature Flag'),
    url: featureFlagPath(),
    icon: 'icon-setting-2',
    superAdmin: true,
  },
]
